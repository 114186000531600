import gql from 'graphql-tag'

export const fetchMultiProductsToCartMutation = gql(`
mutation ($cart_id: String!, $products: [CartItemInput!]!) {
  addProductsToCart(
    cartId: $cart_id
    cartItems: $products
  ) {
      user_errors {
        code
        message
      }
    }
  }
`)
