export enum BasketActionTypes {
  'RESET_SUGGESTED_BASKETS' = 'RESET_SUGGESTED_BASKETS',
  'CREATE_EMPTY_CART' = 'CREATE_EMPTY_CART',
  'FETCH_CART_QUERY' = 'FETCH_CART_QUERY',
  'FETCH_PRODUCTS' = 'FETCH_PRODUCTS',
  'FETCH_BASKET_ID' = 'FETCH_BASKET_ID',
  'FETCH_ADD_TO_BASKET' = 'FETCH_ADD_TO_BASKET',
  'FETCH_ADD_MULTI_TO_BASKET' = 'FETCH_ADD_MULTI_TO_BASKET',
  'REMOVE_ITEM' = 'REMOVE_ITEM',
  'UPDATE_ITEMS' = 'UPDATE_ITEMS',
  'UPDATE_PRICES' = 'UPDATE_PRICES',
  'FETCH_SHIPPING_ADDRESS' = 'FETCH_SHIPPING_ADDRESS',
  'FETCH_SHIPPING_METHODS' = 'FETCH_SHIPPING_METHODS',
  'PLACE_ORDER' = 'PLACE_ORDER',
  'FETCH_TRANSPORT_TYPE' = 'FETCH_TRANSPORT_TYPE',
  'SAVE_ORDER_FOR_LATER' = 'SAVE_ORDER_FOR_LATER',
  'FETCH_MIN_CART_QUERY_FOR_CATALOG' = 'FETCH_MIN_CART_QUERY_FOR_CATALOG',
  'FETCH_HP_CART_QUERY' = 'FETCH_HP_CART_QUERY',
  'FETCH_CART_VALUES' = 'FETCH_CART_VALUES',
  'GET_FIRST_AVAILABLE_DATE' = 'GET_FIRST_AVAILABLE_DATE',
  'FETCH_CUSTOMER_LAST_SETTINGS' = 'FETCH_CUSTOMER_LAST_SETTINGS',
  'CREATE_SUGGESTED_CART' = 'CREATE_SUGGESTED_CART',
  'GET_AVAILABLE_DAYS' = 'GET_AVAILABLE_DAYS',
  'FETCH_SUGGESTED_CART' = 'FETCH_SUGGESTED_CART',
  'GET_DELIVERY_AVAILABLE_DATE' = 'GET_DELIVERY_AVAILABLE_DATE'
}
