import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"
import _imports_0 from '../library/assets/close.svg'


const _withScopeId = n => (_pushScopeId("data-v-0bb8eabc"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { class: "row w-auto flex-0" }
const _hoisted_3 = { class: "col-1 d-flex align-items-center" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "col-9 d-flex align-items-center ps-3" }
const _hoisted_6 = {
  type: "headline-1",
  color: "primary-black"
}
const _hoisted_7 = { class: "col-2 d-flex align-items-center justify-content-end" }
const _hoisted_8 = { class: "py-3 flex-1 overflow-y-auto" }
const _hoisted_9 = { class: "py-1 flex-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AsahiRadioButton = _resolveComponent("AsahiRadioButton")!
  const _component_AsahiButton = _resolveComponent("AsahiButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: "basic-modal-wrap",
    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.closeModal && _ctx.closeModal(...args)))
  }, [
    _createElementVNode("div", {
      class: "basic-modal bg-white p-3 m-4 d-flex flex-column max-h-100 w-fill",
      onClick: _withModifiers(() => { }, ["stop"])
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("img", {
            alt: "icon",
            class: "asahi-address-icon black-color-png",
            src: _ctx.icon
          }, null, 8, _hoisted_4)
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("asahi-typography", _hoisted_6, _toDisplayString(_ctx.title?.toUpperCase()), 1)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("img", {
            alt: "icon",
            class: "asahi-address-icon",
            onClick: _cache[0] || (_cache[0] = () => _ctx.closeModal()),
            src: _imports_0
          })
        ])
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_AsahiRadioButton, {
          onChooseOption: _ctx.selectOption,
          isWhite: _ctx.isWhite,
          name: _ctx.name,
          options: _ctx.options,
          selectedValue: _ctx.selectedOption,
          "text-align": "left"
        }, null, 8, ["onChooseOption", "isWhite", "name", "options", "selectedValue"])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_AsahiButton, {
          type: "primary",
          class: "w-100",
          onClick: _ctx.applyChanges
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('Select')), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ], 8, _hoisted_1)
  ]))
}